import { CasinoGameTag } from "@finbackoffice/enums";
import {
    CasinoGameTypeEnum,
    CasinoWidgetFolderEnum,
    CasinoSectionsStyleEnum,
    CasinoWidgetImgSrcEnum,
    ProvidersEnum,
} from "@finbackoffice/fe-core";
import {
    CasinoScopesMap,
    ICasinoDataState,
    PaginationType,
    UseCasinoGamesHookOptions,
} from "@finbackoffice/site-core";
import { createContext, Dispatch, FC, SetStateAction, useMemo } from "react";
import dynamic from "next/dynamic";
import { INotificationState, useCasinoGames } from "hooks";
import PreloadComponent from "components/base/preload-component/PreloadComponent";
import { CasinoGamesRowVariantEnum } from "../base/games-style/row/CasinoGamesRow";

const CasinoGamesMosaic = dynamic(() => import("../base/games-style/mosaic/CasinoGamesMosaic"), {
    loading: () => <PreloadComponent style={{ height: 356 }} />,
    ssr: false,
});

const CasinoGamesRow = dynamic(() => import("../base/games-style/row/CasinoGamesRow"), {
    loading: () => <PreloadComponent style={{ height: 265 }} />,
    ssr: false,
});

const CasinoGamesShop = dynamic(() => import("../base/games-style/shop/CasinoGamesShop"), {
    loading: () => <PreloadComponent style={{ height: 381 }} />,
    ssr: false,
});

type ICasinoGamesVariant = CasinoGamesRowVariantEnum;

type IProps = {
    id: string;
    style: CasinoSectionsStyleEnum;
    tag?: CasinoGameTag;
    gameTypes: CasinoGameTypeEnum[] | CasinoGameTypeEnum;
    options?: UseCasinoGamesHookOptions;
    provider?: ProvidersEnum | "All";
    notification?: INotificationState;
    isSlots?: boolean;
    hideTag?: CasinoGameTag[];
    variant?: ICasinoGamesVariant;
    label?: string | { title: string; sub_title: string }; // TODO remove in the future
    href?: string;
    wrapperClassName?: string;
    backImg?: boolean;
    imgSrc?: CasinoWidgetImgSrcEnum;
    imgFolder: CasinoWidgetFolderEnum;
};

export type ICasinoGamesContext = {
    id: string;
    isSlots: boolean;
    casinoGamesState: ICasinoDataState;
    setPagination: Dispatch<SetStateAction<PaginationType>>;
    hasMore: boolean;
    notification: INotificationState;
    hideTag?: CasinoGameTag[];
    variant?: ICasinoGamesVariant;
    label?: string | { title: string; sub_title: string };
    href?: string;
    options: UseCasinoGamesHookOptions | undefined;
    wrapperClassName?: string;
    tag?: CasinoGameTag;
    imgSrc?: CasinoWidgetImgSrcEnum;
    imgFolder: CasinoWidgetFolderEnum;
};

export const CasinoGamesContext = createContext<ICasinoGamesContext>(null as any);

const CasinoGames: FC<IProps> = ({
    id,
    style,
    tag,
    gameTypes,
    options,
    hideTag,
    variant,
    label,
    provider = "All",
    href,
    wrapperClassName,
    backImg,
    imgSrc,
    imgFolder,
}) => {
    const gameTypesMemo = useMemo(
        () => (typeof gameTypes === "string" ? [gameTypes] : gameTypes),
        [gameTypes],
    );
    const { casinoGamesState, setPagination, hasMore, notification } = useCasinoGames(
        gameTypesMemo,
        tag ?? "",
        provider,
        options,
    );
    const isSlots = gameTypes === CasinoScopesMap.slots;

    const renderContent = useMemo(() => {
        switch (style) {
            case CasinoSectionsStyleEnum.Row:
                return (
                    <CasinoGamesRow
                        variant={variant || CasinoGamesRowVariantEnum.Horizontal}
                        tag={tag}
                    />
                );
            case CasinoSectionsStyleEnum.RoundRow:
                return (
                    <CasinoGamesRow
                        variant={CasinoGamesRowVariantEnum.RoundedHorizontal}
                        backImg={backImg}
                    />
                );
            case CasinoSectionsStyleEnum.MiniRow:
                return <CasinoGamesRow variant={CasinoGamesRowVariantEnum.SquaredHorizontal} />;
            case CasinoSectionsStyleEnum.WideRow:
                return <CasinoGamesRow variant={CasinoGamesRowVariantEnum.Wide} />;
            case CasinoSectionsStyleEnum.NarrowRow:
                return <CasinoGamesRow variant={CasinoGamesRowVariantEnum.Narrow} />;
            case CasinoSectionsStyleEnum.Mosaic:
            case CasinoSectionsStyleEnum.MosaicMix:
            case CasinoSectionsStyleEnum.MosaicRain:
                return <CasinoGamesMosaic layout={style} />;
            case CasinoSectionsStyleEnum.Shop:
                return <CasinoGamesShop />;
            default:
                return null;
        }
    }, [style, variant, tag, backImg]);

    const value = useMemo(
        () => ({
            id,
            casinoGamesState,
            isSlots,
            hasMore,
            notification,
            setPagination,
            label,
            hideTag,
            href,
            options,
            wrapperClassName,
            imgSrc,
            imgFolder,
        }),
        [
            id,
            casinoGamesState,
            isSlots,
            hasMore,
            notification,
            setPagination,
            label,
            hideTag,
            href,
            options,
            wrapperClassName,
            imgSrc,
            imgFolder,
        ],
    );

    return <CasinoGamesContext.Provider value={value}>{renderContent}</CasinoGamesContext.Provider>;
};

export default CasinoGames;
